
$background: "background";
$text: "text";
$brand: rgb(18,147,111);
$hyperlink: rgb(38,185,252);


$titlebar-background: "titlebar-background";
$titlebar-text: "titlebar-text";
$todo-table-cell-hover: "todo-table-cell-hover";
$todo-table-header-background: "todo-table-header-background";
$todo-table-header-border: "todo-table-header-border";
$itemdetails-background: "itemdetails-background";
$itemdetails-toolbar-background: "itemdetails-toolbar-background";
$itemdetails-toolbar-hover: "itemdetails-toolbar-hover";
$itemdetails-text: "itemdetails-text";
$itemdetails-disabled: "itemdetails-disabled";
$itemdetails-hover: "itemdetails-hover";
$itemdetails-border: "itemdetails-border";
$itemdetails-disabled-text: "itemdetails-disabled-text";
$itemdetails-screen-background: "itemdetails-screen-background";

$dashboards-text: "dashboards-text";
$dashboards-background: "dashboards-background";
$dashboards-tile-background: "dashboards-tile-background";
$dashboards-tile-border: "dashboards-tile-border";
$dashboards-itemview-row-background: "dashboards-itemview-row-background";

$dashboards-gauge: "dashboards-gauge";
$dashboards-gauge-upper: "dashboards-gauge-upper";
$dashboards-gauge-middle: "dashboards-gauge-middle";
$dashboards-gauge-lower: "dashboards-gauge-lower";
$dashboards-gauge-off: "dashboards-gauge-off";

$themes: (
    light: (
			background: rgb(255,255,255),
			text: rgb(38,38,38),
			titlebar-background: #404040,
			titlebar-text: white,
			todo-table-cell-hover: rgb(242,244,247),
			todo-table-header-background: rgb(247,247,247),
			todo-table-header-border: rgb(222,222,222),		
			itemdetails-screen-background: rgb(255,255,255),
			itemdetails-background: rgb(255,255,255),
			itemdetails-toolbar-background: #404040,
			itemdetails-toolbar-hover: #404040,
			itemdetails-text: rgb(38,38,38),
			itemdetails-disabled: rgb(251,251,251),
			itemdetails-hover: rgb(242,244,247),
			itemdetails-border: rgb(222,222,222),
			itemdetails-disabled-text: rgb(171,171,171),
			dashboards-text: rgb(38,38,38),
			dashboards-background: rgb(251,251,251),
			dashboards-tile-background: rgb(255,255,255),
			dashboards-tile-border: rgb(222,222,222),
			dashboards-itemview-row-background: rgb(251,251,251),
			dashboards-gauge: rgb(230,230,230),
			dashboards-gauge-upper: rgb(255,0,0),
			dashboards-gauge-middle: rgb(255,211,0),
			dashboards-gauge-lower: rgb(0,211,0),
			dashboards-gauge-off: rgb(255,255,255),
	),

    dark: (
			background: rgb(45,46,50),
			titlebar-background: rgb(38, 38, 38),
			titlebar-text: rgb(255,255,255),
			text: rgb(255,255,255),
			todo-table-cell-hover: rgb(58,59,64),
			todo-table-header-background: rgb(40,41,46),
			todo-table-header-border: rgb(69,70,77),	
			itemdetails-screen-background: rgb(40,41,46),
			itemdetails-background: rgb(48,50,54),
			itemdetails-toolbar-background: rgb(40,41,46),
			itemdetails-toolbar-hover: rgb(58,59,64),
			itemdetails-text: rgb(255,255,255),
			itemdetails-disabled: rgb(44,46,50),
			itemdetails-hover: rgb(58,59,64),
			itemdetails-border: rgb(69,70,77),
			itemdetails-disabled-text: rgb(102,102,102),
			dashboards-text: rgb(255,255,255),
			dashboards-background: rgb(45,46,50),
			dashboards-tile-background: rgb(48,50,54),
			dashboards-tile-border: rgb(69,70,77),
			dashboards-itemview-row-background: rgb(45,46,50),
			dashboards-gauge: #45474d,
			dashboards-gauge-upper: rgb(255,0,0),
			dashboards-gauge-middle: rgb(255,211,0),
			dashboards-gauge-lower: rgb(0,211,0),
			dashboards-gauge-off: rgb(48,50,54),
    ),
);

@mixin themed() {
	@each $theme, $map in $themes {
    	.theme--#{$theme} & {
    		$theme-map: () !global;
    		@each $key, $submap in $map {
        		$value: map-get(map-get($themes, $theme), '#{$key}');
          		$theme-map: map-merge($theme-map, ($key: $value)) !global;
        	}
        	@content;
        	$theme-map: null !global;
      	}
    }
}

@function t($key) {
	@return map-get($theme-map, $key);
}