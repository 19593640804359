@import "../../../palette";

.itemview {
	overflow: auto;
	@include themed() {
		color: t($text);
	}

	.header {
		.cell {
			font-weight: 700;
		}
	}
	.cell {
		overflow: hidden;
		text-overflow: ellipsis;
		padding: 4px 32px 4px 0;
		white-space: nowrap;
		max-width: 300px;
		text-align: left;
	}
}