@import "../../../palette";

.comment-button {
  color: white;
  cursor: pointer;
}

.comment-button:hover {
  text-decoration: underline;
}

.comment-button.is-positive {
  color: #12936F;
}

.Comment-Button-Positive {
  background-color:#12936F !important;
  color: #FFFFFF !important;
  text-align: right;
}

.Comment-Button-Positive:hover {
  background-color: #157756 !important;
}

.Comment-Button-Positive:active {
  background-color: #55b58c !important;
}

.Comment-Button-Negative {
  @include themed() {
    color: t($itemdetails-text);
    background-color: t($itemdetails-background);
    border: 1px solid t($itemdetails-border);
  }
  text-align: right;
  width: 90px;
}

.Comment-Button-Negative:hover {
  @include themed() {
    color: t($itemdetails-text);
    background-color: t($itemdetails-hover);
    border: 1px solid t($itemdetails-border);
  }
}

.Comment-Button-Negative:active {
  @include themed() {
    color: t($itemdetails-text);
    background-color: t($itemdetails-background);
    border: 1px solid t($itemdetails-border);
  }
}

.comment-emptystate {
  color: rgb(171, 172, 176);
  font-size: 0.95em;
}

.commentitem-container {
  display: flex;
  flex-flow: column nowrap;
  -ms-word-break: break-all;
  word-break: break-all;

/* Non standard for webkit */
   word-break: break-word;

  -webkit-hyphens: auto;
     -moz-hyphens: auto;
      -ms-hyphens: auto;
          hyphens: auto;
}

.commentitem-container + .commentitem-container {
  margin: 24px 0 0 0;
}

.commentitem-details {
  display: flex;
  flex-flow: row nowrap;
  margin: 0 0 8px 0;
}

.commentitem-author {
  font-weight: 700;
  margin: 0 8px 0 0;
}

.commentitem-date-text {
  color:#ABACB0;
}

.commentitem-content {
  @include themed() {
    background-color: t($itemdetails-screen-background);
    border: 1px solid t($itemdetails-border);
  }

  border-radius: 4px;
  padding: 8px 12px;
  margin: 0 0 8px 0;
}

.commentitem-edit {
  color: $brand;
  cursor: pointer;
  font-size: 0.9em;
  flex: 0 0 auto;
}

.commentitem-edit:hover {
  text-decoration: underline;
}

.rdw-suggestion-dropdown {
  color: black;
}

.rdw-mention-link {
  background-color: initial;
  color: $hyperlink;
}