@import "../../../palette";

[class="0-legend"] {
    background-color: red;
}

.charttile {
    @include themed() {
        background-color: t($dashboards-tile-background);
       border: 1px solid t($dashboards-tile-border);
    }
    border-radius: 4px;
    margin: 32px 0 0 0;
    overflow: hidden;
    padding: 16px;
    width: 100%;
    .name {
        font-weight: 700;
        margin: 0 0 16px 0;
    }

    .legend {
        font-size: 12px;
        justify-content: center;
        margin: 0 0 0 16px;
        padding: 0 8px 0 0;
        overflow: auto;
        .entry {
            align-items: center;
            display: flex;
            flex-flow: row nowrap;
            margin: 0 0 8px 0;
            .text {
                flex: 1 1 auto;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
            .icon {
                flex: 0 0 10px;
                height: 10px;
                width: 10px;
                margin: 0 8px 0 0;
                &.upper {
                    @include themed() {
                        background-color: t($dashboards-gauge-upper);
                    }
                }
                &.middle {
                    @include themed() {
                        background-color: t($dashboards-gauge-middle);
                    }
                }
                &.lower {
                    @include themed() {
                        background-color: t($dashboards-gauge-lower);
                    }
                }
            }
        }
    }
}