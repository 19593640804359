.Copyright {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1rem;
  text-align: center;
  font-size: 11px;
  color: #8a8a8a;

  .List  {
    list-style: none outside none;
  };

  .Link {
    color: #8a8a8a;
    text-decoration: none;

    :hover {
      text-decoration: underline;
    }
  }
}

