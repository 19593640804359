.Login {
    font-family: 'Roboto', sans-serif;
}

.LoginForm {
  display: block;
  margin-bottom: 20px;

  input {
    width: 340px !important;
    border: 1px solid #b4bcc3 !important;
    border-radius: 2px !important;
    border-color: #98a1a7 !important;
    font-family: 'Roboto', sans-serif !important;
  }
}

.LoginButton {
  text-align: right;
  background-color: #12936F !important;
  font-family: 'Roboto', sans-serif !important;

  :hover {
    text-align: right;
    background-color: #157756 !important;
    font-family: 'Roboto', sans-serif !important;
  }

  :active {
    text-align: right;
    background-color: #55b58c !important;
    font-family: 'Roboto', sans-serif !important;
  }
}

 .LoginMessageStyle {
  width: 400px;

   .Label {
     font-weight: bold;
   }
};

.LoginPageStyle {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 500;
  background-image: url('../../images/login-helix.png');
  background-repeat: no-repeat;
  background-color: #2b2b2d;

  .Center {
    outline: 1px solid black;
    padding: 30px;
    background-color: white;
    border-bottom: 4px solid #12936F;
    border-top: 34px hidden #2b2b2d;
  };

  .PasswordField {
    display: block;
    margin-bottom: 20px !important;
  };

  .Button {
    text-align: right;
    background-color: #12936F !important;
  };
};
