@import "../../palette";

.tasklist {
	overflow: auto;

	table {
		border-collapse: collapse;
		table-layout: fixed;
		width: 100%;
		th {
			@include themed() {
				background-color: t($todo-table-header-background);
			}
			padding: 8px 16px;
		}
		th, td {
			@include themed() {
				border: 1px solid t($todo-table-header-border);
			}
			max-width: 15%;
			text-align: left;
			width: 15%;
			.values {
				align-items: center;
				display: flex;
				
				.value {
					align-items: center;
					display: flex;
					flex-flow: row nowrap;
					.icon {
						height: 16px;
						width: 16px;
						margin: 0 4px 0 0;
					}
				}
				.value + .value {
					margin: 0 0 0 16px;
				}
			}
			.integration-button {
				align-items: center;
				// display: flex;
				// flex-flow: row nowrap;
				border-bottom: 2px solid transparent;
				cursor: pointer;
				
				// white-space: nowrap;
				text-align: center;
				// text-overflow: clip;
				// text-overflow: ellipsis;
				// content-width: width;
				// width: 110px;
				// width: fit-content;
				width: 100%;
				max-width: 100%;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				  
	
				&.is-activated {
					background-color: $brand;
					border-radius: 4px;
					padding: 8px 16px;
					&:hover {
						background-color: lighten($brand, 5%);
					}
				}

				&.is-deactivated {
					// color: t($itemdetails-text);
					@include themed() {
						background-color: t($itemdetails-disabled-text);
					}  
					border-radius: 4px;
					padding: 8px 16px;
					&:hover {
						@include themed() {
							background-color: lighten(t($itemdetails-disabled-text), 5%);
						} 
					}
				}
			}
			.description {
				display: flex;
				flex-flow: column nowrap;
				.project {
					color: #C2C2C2;
					font-size: 12px;
				}

				a {
					@include themed() {
						color: t($text);
					}
				}
			}
			.singleselect-container .singleselect {
				background-color: transparent;
				border: 1px solid transparent;
				padding: 8px 16px;
			}
			&:not(.no-padding) {
				padding: 8px 16px;
			}
			&.group {
				@include themed() {
					border-bottom: 1px solid t($todo-table-header-border) !important;
					border-top: 1px solid t($todo-table-header-border) !important;
				}
				color: $brand;
				font-size: 16px;
				font-weight: 700;
				padding: 16px;
			}
			&:not(.is-wide) {
			  overflow: hidden;
			  text-overflow: ellipsis;
			  white-space: nowrap;
			}
			&.is-wide {
			  width: 40%;
			  max-width: 40%;
			}
			&.is-narrow {
			  width: 10%;
			  max-width: 10%;
			}
		}
		td {
			&:not(.is-disabled) {
				cursor: pointer;
				&:hover {
					@include themed() {
						background-color: t($todo-table-cell-hover);
					}
				}
			}
		}
		tbody {
			margin: 0 0 48px 0;
		}
	}
}

.todo {
	table {
		td {
			border: 1px solid transparent !important;
		}
	}
}

@media only screen and (max-width: 512px) {
	.todo {
		.tasklist {
			td:not(.is-wide) {
				white-space: initial !important;
			}
	
		}

		table {
			th.DueDate, th.Priority, th.WorkRemaining, td.DueDate, td.Priority, td.WorkRemaining {
				display: none;
				visibility: hidden;
				max-width: 0%;
				width: 0%;
			}
			th.Description {
				width: 40%;
			}
			th.Status {
				width: 60%;
			}
		}
	}
}