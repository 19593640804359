.integrationHeader {
  text-align: center;
  font-size: 30px;
  position: relative;
  margin: auto;
}

.integrationBody {
  text-align: center;
  font-size: 20px;
  position: relative;
  margin: auto;
}