@import "../../../palette";

.ui.popup.gauge-tooltip {
	background-color: rgba(0, 0, 0, 0.8);
	border: 0;
	color: white;
	font-family: 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
	font-size: 12px;
	padding: 6px;
}

.gauge-tooltip:before {
	background: rgba(0, 0, 0, 0.8) !important;
	border: 0 !important;
}

.gaugecontainer {
	align-items: center;
	display: flex;
	flex-flow: row nowrap;
	@include themed() {
		color: t($text);
	}
	.gauge {
		@include themed() {
			background-color: t($dashboards-gauge);
		}
		border-radius: 32px;
		display: flex;
		flex-flow: column nowrap;
		justify-content: space-between;
		margin: 0 auto;
		padding: 16px 0 16px 0;
		
		.light {
			@include themed() {
				background-color: t($dashboards-gauge-off);
				border: 1px solid t($dashboards-gauge-off);
			}
			border-radius: 50%;
			margin: 0 auto 8px;
	
			&.is-active {
				&.upper {
					@include themed() {
						background-color: t($dashboards-gauge-upper);
					}
				}
				&.middle {
					@include themed() {
						background-color: t($dashboards-gauge-middle);
					}
				}
				&.lower {
					@include themed() {
						background-color: t($dashboards-gauge-lower);
					}
				}
			}
		}
	}
}

