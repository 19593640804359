@import "../../../palette";

.integrationBlock {
  flex: 0 0 100px;
  height: 60px;
  @include themed() {
    background-color: t($titlebar-background);
    color: t($text);
  } 
}
