.displayFlex {
    display: flex;
    align-items: center;
}

.overflowEllipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
